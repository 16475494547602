.lighten-background.alert {
    @each $color, $value in $theme-colors {
        &.alert-#{$color} {
            background-color: transparentize($value, 0.8) !important;
            color: darken($value, 40%);
            border: 1px solid #ccc;
            box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 5px;
        }
    }
}
