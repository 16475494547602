.video-content {
    height: 0;
    margin: auto;
    z-index: 1;
    position: relative;
    padding-top: 25px;
    padding-bottom: 56.25%;
    display: block;
    overflow: hidden;
}

#video-iframe, .video-content iframe {
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    position: absolute;
}